import { Injectable } from '@angular/core';
import * as csv from 'csvtojson';

import { ParsedCsvContent } from '../interfaces/ParsedFileContent.interface';
import { ServicesModule } from '../services.module';

@Injectable({
	providedIn: ServicesModule
})
export class FileService {
	//error messages
	public readonly ERROR_MESSAGES = {
		INVALID_FILE_TYPE: 'Invalid file type provided',
		MISSING_CSV_HEADERS: (missingHeaders: string[]) => {
			return 'Missing CSV headers for: ' + missingHeaders.join(', ');
		},
		NO_DATA_RETURNED: 'No data / invalid data for file type found in file'
	};

	//valid file types
	public readonly FILE_TYPES = {
		TEXT_CSV: 'text/csv'
	};

	/**
	 * @description Parses the provided file based on type
	 * @param file Selected file to parse
	 * @return Parsed data from file
	 */
	public async parseFile(file: File): Promise<ParsedCsvContent[]> {
		switch (file.type) {
			case this.FILE_TYPES.TEXT_CSV:
				//return CSV as parsed JSON
				return this.parseCsvFile(file);
			default:
				throw new Error(this.ERROR_MESSAGES.INVALID_FILE_TYPE);
		}
	}

	/**
	 * @description Parses CSV file
	 * @param file CSV file
	 * @return Data from CSV
	 */
	public parseCsvFile(file: File): Promise<ParsedCsvContent[]> {
		const reader: FileReader = new FileReader();
		reader.readAsText(file);

		return new Promise((resolve, reject) => {
			reader.onload = async () => {
				try {
					const csvParsed = await csv().fromString(reader.result as string);
					resolve(csvParsed);
				} catch (err) {
					reject(err);
				}
			};
		});
	}

	/**
	 * @description Compares values provided in reference to the headers provided in the CSV row
	 * @param reference Array with required header labels as values
	 * @param rows CSV rows that are being validated
	 * @return Null | list of missing CSV headers + throws error
	 */
	public validateCsvHeaders(reference: string[], rows: ParsedCsvContent[] | undefined) {
		//if no CSV rows are provided ==> throw error
		if (!rows || !rows.length) {
			throw new Error(this.ERROR_MESSAGES.NO_DATA_RETURNED);
		}

		//get properties from reference structure and parsed CSV rows
		const uploadedHeaders = Object.keys(rows[0]);

		//find any headers that are in the reference structure and not the parsed CSV rows
		const missingHeaders = reference.filter(header => !uploadedHeaders.includes(header));

		//if there are any missing headers ==> throw error containing the list of missing headers
		if (missingHeaders.length) {
			throw new Error(this.ERROR_MESSAGES.MISSING_CSV_HEADERS(missingHeaders));
		}
	}

	/**
	 * @description Validates the number of CSV rows returned (currently makes sure that there are > 0 rows)
	 * @param rows CSV rows that are being validated
	 * @return Error if there are no CSV rows
	 */
	public validateCsvLength(rows: ParsedCsvContent[] | undefined) {
		if (!rows?.length) {
			throw new Error(this.ERROR_MESSAGES.NO_DATA_RETURNED);
		}
	}
}
