// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { EnvironmentConfig } from './env.interface';

export const environment: EnvironmentConfig = {
	production: false,
	auth: {
		clientID: 'SrDmQRpVWbWT6K6JtdKt0rfv3xjNMBxX',
		domain: 'dev-g91j1q5t.us.auth0.com',
		audience: 'dev-test-app'
	},
	signatureApiUrl: 'https://signature-wiukstozoq-wl.a.run.app/api'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
