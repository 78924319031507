import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State, STUDY_FEATURE_KEY, studyAdapter } from './study.reducer';

// Lookup the 'Study' feature state managed by NgRx
export const getStudyState = createFeatureSelector<State>(STUDY_FEATURE_KEY);

const { selectAll, selectEntities } = studyAdapter.getSelectors();

export const getStudyLoaded = createSelector(
	getStudyState,
	(state: State) => state.loaded
);

export const getStudyError = createSelector(getStudyState, (state: State) => state.error);

export const getAllStudy = createSelector(getStudyState, (state: State) =>
	selectAll(state)
);

export const getStudyEntities = createSelector(getStudyState, (state: State) =>
	selectEntities(state)
);

export const getSelectedId = createSelector(
	getStudyState,
	(state: State) => state.selectedId
);

export const getSelected = createSelector(
	getStudyEntities,
	getSelectedId,
	(entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
