import { ParsedCsvContent } from '@irh/services';
import { DateTime } from 'luxon';

import { WcdStudyTypes } from '../enums';
import { NormalizedStudyExportRowData } from '../interfaces';
import { StudyExportRowProcessor } from './StudyExportRowProcessor.class';

export class CloudbeatStudyExportRowProcessor extends StudyExportRowProcessor {
	//mapping of study types provided in Cloudbeat CSVs to WCD study types
	protected readonly STUDY_TYPE_MAPPINGS: Map<string, string> = new Map([
		['M.C.T', WcdStudyTypes.MCOT],
		['W.E', WcdStudyTypes.WE],
		['Holter', WcdStudyTypes.HOLTER]
	]);

	//normalized data from the CSV row that is used to generate WCD studies, patients, etc.
	protected normalizedRowData!: NormalizedStudyExportRowData;

	//required fields in the form of { ALIAS_FOR_USE_IN_CODE: 'header value in CSV'}
	public readonly REQUIRED_FIELDS = {
		STUDY_ID: 'Study ID',
		PATIENT_ID: 'Patient ID',
		PATIENT_NAME: 'Patient Name',
		DATE_OF_BIRTH: 'Date Of Birth',
		DEVICE_SERIAL_NUMBER: 'Device Serial Number',
		MEDICAL_CENTER_NAME: 'Medical Center Name',
		PROVIDER_NAME: 'Provider Name',
		STUDY_TYPE: 'Study Type',
		START_TIME: 'Start Time',
		END_TIME: 'End Time',
		DURATION: 'Duration',
		CREATION_DATE_UTC: 'Creation Date (UTC)',
		ARCHIVED: 'Archived?',
		DATE_ARCHIVED_UTC: 'Date Archived (UTC)',
		ALERTS: 'Alerts',
		TOTAL_NUMBER_EVENTS: 'Total Number of Events',
		PATIENT_TRIGGERED_EVENTS: 'Patient Triggered Events',
		AUTO_TRIGGERED_EVENTS: 'Auto-Triggered Events',
		STARTED: 'Started?',
		PATIENT_GENDER: 'Patient Gender',
		PATIENT_AGE: 'Patient Age',
		ICD_10: 'ICD-10',
		EMR_NUMBER: 'EMR #',
		PHONE_NUMBER: 'Phone #',
		TIME_ZONE: 'Time zone',
		ADDRESS: 'Address',
		PRIMARY_INSURANCE_NAME: 'Primary Insurance Name',
		PRIMARY_INSURANCE_ID: 'Primary Insurance Id',
		SECONDARY_INSURANCE_NAME: 'Secondary Insurance Name',
		SECONDARY_INSURANCE_ID: 'Secondary Insurance Id',
		DIAGNOSTICS_DURATION: 'Diagnostics Duration',
		DIAGNOSTICS_PERCENT: 'Diagnostics %'
	};

	constructor(rowData?: ParsedCsvContent) {
		super();

		//process the data and format for database
		if (rowData) {
			this.setNormalizedRowData(rowData);
		}
	}

	/**
	 * @description Processes the provided row data into normalized types to match WCD database schema
	 * @param rowData Data from the CSV row
	 * @return void
	 */
	protected setNormalizedRowData(cloudbeatRowData: ParsedCsvContent) {
		this.normalizedRowData = {
			studyId: this.formatString(cloudbeatRowData[this.REQUIRED_FIELDS.STUDY_ID]),
			patientId: this.formatString(cloudbeatRowData[this.REQUIRED_FIELDS.PATIENT_ID]),
			patientName: this.formatString(cloudbeatRowData[this.REQUIRED_FIELDS.PATIENT_NAME]),
			dob: this.formatString(cloudbeatRowData[this.REQUIRED_FIELDS.DATE_OF_BIRTH]), //format: 01/01/1900
			phonePrimary: this.formatString(
				cloudbeatRowData[this.REQUIRED_FIELDS.PHONE_NUMBER]
			),
			phoneSecondary: undefined,
			timeZone: this.formatString(cloudbeatRowData[this.REQUIRED_FIELDS.TIME_ZONE]), //format: (UTC-05:00) Eastern Time (US & Canada)
			address: this.formatString(cloudbeatRowData[this.REQUIRED_FIELDS.ADDRESS]),
			insurancePrimaryName: this.formatString(
				cloudbeatRowData[this.REQUIRED_FIELDS.PRIMARY_INSURANCE_NAME]
			),
			insurancePrimaryId: this.formatString(
				cloudbeatRowData[this.REQUIRED_FIELDS.PRIMARY_INSURANCE_ID]
			),
			insuranceSecondaryName: this.formatString(
				cloudbeatRowData[this.REQUIRED_FIELDS.SECONDARY_INSURANCE_NAME]
			),
			insuranceSecondaryId: this.formatString(
				cloudbeatRowData[this.REQUIRED_FIELDS.SECONDARY_INSURANCE_ID]
			),
			patientSex: this.formatString(
				cloudbeatRowData[this.REQUIRED_FIELDS.PATIENT_GENDER]
			),
			patientAge: this.formatNumber(cloudbeatRowData[this.REQUIRED_FIELDS.PATIENT_AGE]),
			deviceSerialNumber: this.formatString(
				cloudbeatRowData[this.REQUIRED_FIELDS.DEVICE_SERIAL_NUMBER]
			),
			deviceId: this.formatString(
				cloudbeatRowData[this.REQUIRED_FIELDS.DEVICE_SERIAL_NUMBER]
			),
			deviceModel: undefined,
			deviceManufacturer: undefined,
			deviceImei: undefined,
			medicalCenterName: this.formatString(
				cloudbeatRowData[this.REQUIRED_FIELDS.MEDICAL_CENTER_NAME]
			),
			providerName: this.formatString(
				cloudbeatRowData[this.REQUIRED_FIELDS.PROVIDER_NAME]
			),
			studyType: this.formatStudyType(cloudbeatRowData[this.REQUIRED_FIELDS.STUDY_TYPE]),
			startTime: this.formatDate(
				cloudbeatRowData[this.REQUIRED_FIELDS.START_TIME],
				cloudbeatRowData[this.REQUIRED_FIELDS.TIME_ZONE]
			), //format: 1/1/1900 9:36:00 AM (NOTE: must account for time zone)
			endTime: this.formatDate(
				cloudbeatRowData[this.REQUIRED_FIELDS.END_TIME],
				cloudbeatRowData[this.REQUIRED_FIELDS.TIME_ZONE]
			), //format: 1/1/1900 9:36:00 AM (NOTE: must account for time zone)
			durationInSeconds: this.calculateDuration(
				cloudbeatRowData[this.REQUIRED_FIELDS.START_TIME],
				cloudbeatRowData[this.REQUIRED_FIELDS.END_TIME]
			),
			createdAt: this.formatDate(
				cloudbeatRowData[this.REQUIRED_FIELDS.CREATION_DATE_UTC]
			), //format: 1/1/1900 9:36:00 AM (NOTE: provided in UTC)
			isArchived: this.formatBoolean(cloudbeatRowData[this.REQUIRED_FIELDS.ARCHIVED]),
			archivedAt: this.formatDate(
				cloudbeatRowData[this.REQUIRED_FIELDS.DATE_ARCHIVED_UTC]
			), //format: 1/1/1900 9:36:00 AM (NOTE: provided in UTC)
			alerts: this.formatString(cloudbeatRowData[this.REQUIRED_FIELDS.ALERTS]),
			numTotalEvents: this.formatNumber(
				cloudbeatRowData[this.REQUIRED_FIELDS.TOTAL_NUMBER_EVENTS]
			),
			numPatientEvents: this.formatNumber(
				cloudbeatRowData[this.REQUIRED_FIELDS.PATIENT_TRIGGERED_EVENTS]
			),
			numAutoTriggeredEvents: this.formatNumber(
				cloudbeatRowData[this.REQUIRED_FIELDS.AUTO_TRIGGERED_EVENTS]
			),
			isStarted: this.formatBoolean(cloudbeatRowData[this.REQUIRED_FIELDS.STARTED]),
			icd10: this.formatString(cloudbeatRowData[this.REQUIRED_FIELDS.ICD_10]),
			emrNum: this.formatString(cloudbeatRowData[this.REQUIRED_FIELDS.EMR_NUMBER]),
			diagnosticsDuration: this.formatString(
				cloudbeatRowData[this.REQUIRED_FIELDS.DIAGNOSTICS_DURATION]
			),
			diagnosticsPercentage: this.formatPercentage(
				cloudbeatRowData[this.REQUIRED_FIELDS.DIAGNOSTICS_PERCENT]
			)
		};
	}

	/**
	 * @description Formats a string provided by the Cloudbeat CSV
	 * @param value Value from the CSV row
	 * @return Formatted string if value is not 'N/A' (Cloudbeat exports empty cells as 'N/A')
	 */
	override formatString(value: string): string | undefined {
		if (!value?.length || value === 'N/A') {
			return undefined;
		} else {
			return value.trim();
		}
	}

	/**
	 * @description Formats date provided by Cloudbeat CSV
	 * @param value Date in the format 1/1/1900 9:36:00 AM
	 * @param timeZone Empty string if the date is provided in UTC, otherwise is the provided time zone in format: (UTC-05:00) Eastern Time (US & Canada)
	 * @return Formatted Date
	 */
	override formatDate(value: string, timeZone = ''): Date | undefined {
		try {
			const dateString = this.formatString(value);

			if (dateString) {
				const zone = timeZone?.length ? timeZone?.split('(')[1].split(')')[0] : 'utc';
				return this.getFormattedDateTime(value, zone)?.toUTC().toJSDate();
			} else {
				return undefined;
			}
		} catch (err) {
			console.error('Error parsing date from Cloudbeat CSV: ', value, timeZone);
			return undefined;
		}
	}

	/**
	 * @description Calculates the difference between the provided start and end times
	 * @param startTime Start time in the format 1/1/1900 9:36:00 AM (provided in UTC)
	 * @param endTime End time in the format 1/1/1900 9:36:00 AM (provided in UTC)
	 * @return Number of seconds between the two provided times
	 */
	override calculateDuration(startTime: string, endTime: string): number | undefined {
		const startTimeString = this.formatString(startTime);
		const endTimeString = this.formatString(endTime);

		if (startTimeString && endTimeString) {
			const formattedStartTime = this.getFormattedDateTime(startTimeString, 'utc');
			const formattedEndTime = this.getFormattedDateTime(endTimeString, 'utc');
			return formattedEndTime.diff(formattedStartTime, 'seconds').toObject().seconds;
		} else {
			return undefined;
		}
	}

	/**
	 * @description Parses boolean values provided by Cloudbeat (valid values: 'NO', '0', 'YES', '1')
	 * @param value Value to format
	 * @return Value as boolean
	 */
	override formatBoolean(value: string): boolean | undefined {
		if (value?.length) {
			if (value === 'No') {
				return false;
			} else if (value === 'Yes') {
				return true;
			} else {
				return undefined;
			}
		} else {
			return undefined;
		}
	}

	/**
	 * @description Returns the provided date string as a formatted DateTime
	 * @param value Date string value
	 * @param zone Time zone for use in the date parsing
	 * @return DateTime value
	 */
	private getFormattedDateTime(value: string, zone: string): DateTime {
		return DateTime.fromFormat(value, 'M/d/yyyy h:mm:ss a', {
			zone
		});
	}
}
