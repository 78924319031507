import { Injectable } from '@angular/core';
import { SelectOption, UploadFileOutput } from '@irh/ui';

import { StudyService } from './';

@Injectable()
export class UploadStudyCsvFacadeService {
	//error messages that are displayed to users in the UploadStudyCsvComponent
	public readonly UI_MESSAGES = {
		NO_FILES_SELECTED: 'No files selected.',
		NO_FILE_TYPE_SELECTED: 'Invalid file type.',
		SUCCESS: 'Success.'
	};

	constructor(private readonly studyService: StudyService) {}

	/**
	 * @description Gets the valid CSV Types for the Upload Study CSV component
	 * @return List of valid CSV Types in the form of SelectOptions
	 */
	public getCsvTypes(): SelectOption[] {
		return [
			{
				label: 'Rhythmedix CSV',
				value: this.studyService.STUDY_SOURCES.RHYTHMEDIX
			},
			{
				label: 'Cloudbeat CSV',
				value: this.studyService.STUDY_SOURCES.CLOUDBEAT
			}
		];
	}

	/**
	 * @description Attempts to validate and process uploadFileOutput value from the FileUpload component
	 * @param uploadFileOutput Output from the FileUpload component containing selected file data & metadata
	 * @return Void || throws error if validations fail
	 */
	public async processUploadFileOutput(
		uploadFileOutput: UploadFileOutput
	): Promise<void> {
		//if no files were selected ==> throw error (a file is required)
		if (!uploadFileOutput?.files?.length) {
			throw new Error(this.UI_MESSAGES.NO_FILES_SELECTED);
		}
		//if no file type was provided ==> throw error (it is required)
		else if (!uploadFileOutput?.fileType) {
			throw new Error(this.UI_MESSAGES.NO_FILE_TYPE_SELECTED);
		}
		//else ==> parse file based on type (Cloudbeat / Rhythmedix)
		else {
			/* ----- parse and validate the csv contents (will throw error if CSV is invalid) ----- */
			const { fileType, files } = uploadFileOutput;

			//parse the data from the file
			const data = await this.studyService.parseStudyCsvData(files[0]);

			//validate study data from CSV (throws error if uploaded data is invalid)
			this.studyService.validateStudyCsvData(fileType, data);

			//show success with parsed information on UI for now
			this.studyService.processStudyCsvData(fileType, data);
		}
	}
}
