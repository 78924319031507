<div class="options-toggle-container">
	Sort By:
	<mat-button-toggle-group
		name="sortBy"
		aria-label="Sort By"
		[value]="sortBy"
		(change)="sortByChanged($event)"
	>
		<mat-button-toggle aria-label="newest" value="newest">Newest</mat-button-toggle>
		<mat-button-toggle aria-label="alert level" value="alert level"
			>Alert Level</mat-button-toggle
		>
	</mat-button-toggle-group>
</div>

<div class="options-toggle-container">
	View:
	<mat-button-toggle-group
		name="filterBy"
		aria-label="Filter By"
		[value]="filterBy"
		(change)="filterByChanged($event)"
	>
		<mat-button-toggle aria-label="todo" value="todo">To-Do</mat-button-toggle>
		<mat-button-toggle aria-label="completed" value="completed"
			>Completed</mat-button-toggle
		>
		<mat-button-toggle aria-label="all" value="all">All</mat-button-toggle>
	</mat-button-toggle-group>
</div>
