import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Credentials } from '../../interfaces';
import { ValidationService } from '../../services';

@Component({
	selector: 'irh-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent {
	public loginForm: FormGroup;
	public enterValidEmailMsg = 'Please enter a valid email address';
	public passwordRequiredMsg = 'Password is required';
	public emailRequiredMsg = 'Email is required';
	public passwordInvalidMsg =
		'Password requires a minimum of eight characters, one uppercase letter, one lowercase letter, one number and one special character';

	@Output() submitLogin: EventEmitter<Credentials> = new EventEmitter<Credentials>();

	constructor(validationService: ValidationService) {
		this.loginForm = new FormGroup({
			email: new FormControl('', [Validators.required, Validators.email]),
			password: new FormControl('', validationService.getPasswordValidators())
		});
	}

	public login(): void {
		this.loginForm.markAllAsTouched();
		if (!this.loginForm.valid) {
			return;
		}
		// Form is valid, emit login attempt
		this.submitLogin.emit(this.loginForm.value as Credentials);
	}
}
