import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SelectOption, UploadFileFormValue, UploadFileOutput } from '../../interfaces';

@Component({
	selector: 'irh-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, AfterViewInit {
	/* ----- init Inputs with default values ----- */
	//label for the upload button (to emit uploaded file)
	@Input() uploadFileButtonLabel? = 'Upload';
	//label for the dropdown to select file type
	@Input() fileTypeLabel? = 'Select a Type';
	//options for the dropdown for file type (ex. Rhythmedix CSV, Cloudbeat CSV, etc.)
	@Input() fileTypeOptions?: SelectOption[] = [];
	//true if users should be allowed to select multiple files
	@Input() allowMultipleFiles? = false;

	//message to display on the UI (to show success / error messages)
	@Input() uiMessage?: string;

	//uploaded file information
	@Output() onUploadFileOutput: EventEmitter<UploadFileOutput> =
		new EventEmitter<UploadFileOutput>();

	//input for file selection (available in controller to set "multiple" attribute)
	@ViewChild('fileUploadInput') fileUploadInput!: ElementRef;

	//file upload form
	public uploadFileForm: FormGroup;

	constructor(private fb: FormBuilder) {
		//init upload file form
		this.uploadFileForm = this.fb.group({
			selectedFileType: [null],
			selectedFiles: [
				null,
				Validators.compose([Validators.required, Validators.minLength(1)])
			]
		});
	}

	ngOnInit() {
		//set form validators on upload file form
		this.setFormValidators();
	}

	ngAfterViewInit() {
		//if multiple file selection is supported ==> set 'multiple' attribute on input
		if (this.allowMultipleFiles) {
			(this.fileUploadInput.nativeElement as HTMLInputElement).setAttribute(
				'multiple',
				'multiple'
			);
		}
	}

	/**
	 * @description Dynamically sets validators on the upload file form based on component Inputs
	 * @return void
	 */
	private setFormValidators() {
		//if file type options are provided ==> make selectedFileType required
		this.uploadFileForm
			.get('selectedFileType')
			?.setValidators(this.fileTypeOptions?.length ? Validators.required : null);
	}

	/**
	 * @description Handles event where a file is selected from user's computer
	 * @param eventTarget Event target containing selected files
	 * @return void
	 */
	public handleSelectFile(event: Event) {
		const input = event.currentTarget as HTMLInputElement;
		this.uploadFileForm.patchValue({ selectedFiles: input.files });
	}

	/**
	 * @description Emits value with file data & metadata
	 * @return void
	 */
	public uploadFile() {
		const formValue = this.uploadFileForm.value as UploadFileFormValue;
		const outputValue: UploadFileOutput = {
			fileType: formValue.selectedFileType,
			files: formValue.selectedFiles
		};

		this.onUploadFileOutput.emit(outputValue);
	}
}
