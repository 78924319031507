<form class="debounce-textarea">
	<mat-form-field class="full-width" appearance="fill">
		<mat-label data-cy="label" *ngIf="label">{{ label }}</mat-label>
		<textarea
			[(ngModel)]="value"
			name="text"
			rows="5"
			[disabled]="disabled"
			(keyup)="save()"
			matInput
			data-cy="textarea"
			placeholder="E.g., Data reviewed and incorporated into medical decision making and/or recommendations, if applicable."
		></textarea>
		<div class="controls">
			<button
				*ngIf="value && !disabled"
				matSuffix
				[disabled]="disabled"
				mat-icon-button
				aria-label="Clear"
				(click)="clear()"
				data-cy="clear"
			>
				<mat-icon>close</mat-icon>
			</button>
		</div>
	</mat-form-field>
</form>
