import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as AuthActions from './auth.actions';
import { AuthEntity } from './auth.models';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState extends EntityState<AuthEntity> {
	loading: boolean;
	isLoggedIn: boolean;
	error?: string | null;
}

export interface AuthPartialState {
	readonly [AUTH_FEATURE_KEY]: AuthState;
}

export const authAdapter: EntityAdapter<AuthEntity> = createEntityAdapter<AuthEntity>();

export const initialState: AuthState = authAdapter.getInitialState({
	// set initial required properties
	loading: false,
	isLoggedIn: false,
	error: null
});

const authReducer = createReducer(
	initialState,
	on(AuthActions.loginComplete, state => ({
		...state,
		loading: true
	})),
	on(AuthActions.loginSuccess, state => ({
		...state,
		isLoggedIn: true,
		error: null,
		loading: false
	})),
	on(AuthActions.loginFailure, (state, action) => ({
		...state,
		isLoggedIn: false,
		error: action.error,
		loading: false
	}))
);

export function reducer(state: AuthState | undefined, action: Action) {
	return authReducer(state, action);
}
