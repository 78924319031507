import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

interface ResetPassword {
	email: string;
}

@Component({
	selector: 'irh-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
	public resetPasswordForm: FormGroup;
	public showResetForm = false;

	@Output() resetPW: EventEmitter<ResetPassword> = new EventEmitter<ResetPassword>();

	constructor() {
		this.resetPasswordForm = new FormGroup({
			// eslint-disable-next-line @typescript-eslint/unbound-method
			email: new FormControl('', [Validators.required, Validators.email])
		});
	}

	public resetPassword() {
		this.resetPasswordForm.markAllAsTouched();
		if (!this.resetPasswordForm.valid) {
			return;
		}
		// Form is valid, emit reset attempt
		this.resetPW.emit(this.resetPasswordForm.value as ResetPassword);
	}
}
