import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as AuthActions from './auth.actions';
import * as AuthSelectors from './auth.selectors';

@Injectable()
export class AuthFacade {
	auth$ = this.store.pipe(select(AuthSelectors.selectAuthState));
	error$ = this.store.pipe(select(AuthSelectors.selectAuthError));

	constructor(private readonly store: Store) {}

	/**
	 * @description Dispatch Login() action
	 * @return void
	 */
	public dispatchLogin() {
		this.store.dispatch(AuthActions.login());
	}

	/**
	 * @description Dispatch CheckLogin() action
	 * @return void
	 */
	public dispatchCheckLogin() {
		this.store.dispatch(AuthActions.checkLogin());
	}

	/**
	 * @description Dispatch LoginComplete() action
	 * @return void
	 */
	public dispatchLoginComplete() {
		this.store.dispatch(AuthActions.loginComplete());
	}

	/**
	 * @description Dispatch LoginSuccess() action
	 * @return void
	 */
	public dispatchLoginSuccess() {
		this.store.dispatch(AuthActions.loginSuccess());
	}

	/**
	 * @description Dispatch LoginFailure() action
	 * @param errorMessage Error message to present to user
	 * @return void
	 */
	public dispatchLoginFailure(errorMessage: string) {
		this.store.dispatch(AuthActions.loginFailure({ error: errorMessage }));
	}

	/**
	 * @description Dispatch Logout() action
	 * @return void
	 */
	public dispatchLogout() {
		this.store.dispatch(AuthActions.logout());
	}
}
