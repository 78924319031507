import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
	selector: 'irh-debounce-textarea',
	templateUrl: './debounce-textarea.component.html',
	styleUrls: ['./debounce-textarea.component.scss']
})
export class DebounceTextareaComponent {
	@Input() value!: string;
	@Input() label = '';
	@Input() disabled = false;
	@Output() onTextareaUpdate = new EventEmitter<string>();
	debouncer: Subject<string> = new Subject<string>();

	constructor() {
		this.debouncer
			.pipe(debounceTime(100))
			.subscribe((value: string) => this.onTextareaUpdate.emit(value));
	}

	clear() {
		this.value = '';
		this.debouncer.next(this.value);
	}

	save() {
		this.debouncer.next(this.value);
	}
}
