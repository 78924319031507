import { Component, OnInit } from '@angular/core';
import { FileUploadComponentInputs, UploadFileOutput } from '@irh/ui';

import { UploadStudyCsvFacadeService } from '../../services';

@Component({
	selector: 'irh-upload-study-csv',
	templateUrl: './upload-study-csv.component.html',
	styleUrls: ['./upload-study-csv.component.scss']
})
export class UploadStudyCsvComponent implements OnInit {
	//configuration for the file upload component
	public fileUploadConfig!: FileUploadComponentInputs;

	constructor(private readonly facade: UploadStudyCsvFacadeService) {}

	ngOnInit() {
		this.fileUploadConfig = {
			fileTypeOptions: this.facade.getCsvTypes(),
			fileTypeLabel: 'Select CSV Source',
			uiMessage: ''
		};
	}

	/**
	 * @description Handles selected file from FileUpload component
	 * @param uploadFileOutput Selected file information
	 * @return void
	 */
	public async handleUploadFileOutput(uploadFileOutput: UploadFileOutput) {
		try {
			//reset UI message
			this.fileUploadConfig.uiMessage = '';

			//attempt to process file contents
			await this.facade.processUploadFileOutput(uploadFileOutput);

			//if no error was thrown ==> update UI message for users
			this.fileUploadConfig.uiMessage = 'Success.';
		} catch (err) {
			//display error messages on the UI
			this.fileUploadConfig.uiMessage = (err as Error).message;
		}
	}
}
