<div class="patient-search-field">
	<mat-form-field appearance="fill">
		<mat-label> Patient Name Search: </mat-label>
		<input
			data-cy="patientNameInput"
			#patientNameInput
			matInput
			placeholder="Patient..."
			value=""
			type="search"
		/>
		<mat-icon matPrefix>search</mat-icon>
	</mat-form-field>
</div>
<div class="patient-search-field">
	<mat-form-field appearance="fill">
		<mat-label>Report Type: </mat-label>
		<select matNativeControl required (change)="reportTypeChanged($event)">
			<option value="rsw-we-eos-report">rsw we eos report</option>
			<option value="cloudbeat-eos-report">cloudbeat eos report</option>
			<option value="rsw-mct-eos-report">rsw mct eos report</option>
			<option value="FileMaker-30-day-ILR-Report">FileMaker 30 day ILR Report</option>
			<option value="implicity-90-day-remote-report">
				implicity 90 day remote report
			</option>
		</select>
	</mat-form-field>
</div>
