import { createAction, props } from '@ngrx/store';

import { StudyEntity } from './study.models';

enum StudyActionTypes {
	Init = '[Study/API] Init',
	LoadStudies = '[Study/API] Load Studies',
	LoadStudiesSuccess = '[Study/API] Load Studies Success',
	LoadStudiesFailure = '[Study/API] Load Studies Failure'
}

export const loadStudies = createAction(StudyActionTypes.LoadStudies);

export const loadStudiesSuccess = createAction(
	StudyActionTypes.LoadStudiesSuccess,
	props<{ payload: StudyEntity[] }>()
);

export const loadStudiesFailure = createAction(
	StudyActionTypes.LoadStudiesFailure,
	props<{ error: Error }>()
);
