<table
	[attr.aria-label]="caption"
	mat-table
	data-cy="data-table"
	[dataSource]="dataSource"
	class="mat-elevation-z8"
>
	<ng-container *ngFor="let column of columnsToDisplay">
		<ng-container [matColumnDef]="column.label">
			<th [id]="column.property" mat-header-cell *matHeaderCellDef>
				{{ column.label }}
			</th>
			<td mat-cell *matCellDef="let dataRow">{{ dataRow[column.property] }}</td>
		</ng-container>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	<tr mat-row *matRowDef="let dataRow; columns: displayedColumns"></tr>

	<tr class="mat-row" *matNoDataRow>
		<td data-cy="no-data-message" class="mat-cell">No data found.</td>
	</tr>
</table>
