import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AuthEffects } from './+state/auth.effects';
import { AuthFacade } from './+state/auth.facade';
import * as fromAuth from './+state/auth.reducer';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthGuard } from './guards';
import { CallbackComponent } from './pages/callback/callback.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { AuthService } from './services/auth.service';

@NgModule({
	declarations: [ResetPasswordPageComponent, CallbackComponent, NotFoundComponent],
	imports: [
		CommonModule,
		AuthRoutingModule,
		StoreModule.forFeature(fromAuth.AUTH_FEATURE_KEY, fromAuth.reducer),
		EffectsModule.forFeature([AuthEffects])
	],
	providers: [AuthGuard, AuthService, AuthFacade]
})
export class AuthModule {}
