import { SelectOption } from './SelectOption.interface';

export interface FileUploadComponentInputs {
	uploadFileButtonLabel?: string;
	fileTypeLabel?: string;
	fileTypeOptions?: SelectOption[];
	allowMultipleFiles?: boolean;
	uiMessage?: string;
}

export interface UploadFileFormValue {
	selectedFileType: string;
	selectedFiles: FileList;
}
