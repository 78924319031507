import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthFacade } from './auth/+state/auth.facade';

@Component({
	selector: 'irh-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	routes = [
		{
			path: '/wcd',
			displayName: 'WCD'
		},
		{
			path: '/signature',
			displayName: 'Signature'
		}
	];
	activeRoute = '';
	constructor(public readonly authFacade: AuthFacade, private readonly router: Router) {
		this.router.events.subscribe(() => {
			this.activeRoute = this.getRootPath(this.router.url);
		});
	}

	ngOnInit(): void {
		this.authFacade.dispatchCheckLogin();
	}

	logout() {
		this.authFacade.dispatchLogout();
	}

	login() {
		this.authFacade.dispatchLogin();
	}

	async changeNavigation(route: string) {
		this.activeRoute = this.getRootPath(this.router.url);
		await this.router.navigateByUrl(route);
	}

	getRootPath(path: string) {
		return path.slice(0, path.split('/')[1].length + 1);
	}
}
