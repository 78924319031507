import { ParsedCsvContent } from '@irh/services';
import { DateTime } from 'luxon';

import { NormalizedStudyExportRowData, WcdPatient, WcdStudy } from '../interfaces';

/**
 * @description Abstract class that is used to parse and format Study information from CSV exports
 */
export abstract class StudyExportRowProcessor {
	//CSV row that has been normalized for WCD data model
	protected abstract normalizedRowData: NormalizedStudyExportRowData;

	//mapping from study types (as defined in external sources) to WCD study types (e.g. M.C.T => MCOT)
	protected STUDY_TYPE_MAPPINGS!: Map<string, string>;

	//required CSV headers in the form of { alias: 'CSV header label' }
	public abstract REQUIRED_FIELDS?: { [key: string]: string };

	/* ----- DATA FORMATTERS THAT ARE OVERWRITTEN AS NEEDED (BASED ON INTEGRATION) ----- */
	/**
	 * @description Parses the provided row data into normalized WCD data types
	 * @param rowData Row data to process
	 * @return void
	 */
	protected abstract setNormalizedRowData(rowData?: ParsedCsvContent): void;

	/**
	 * @description Standard formatter for mapping study types
	 * @param value Name of the study type as defined in the external system
	 * @return WCD study type
	 */
	protected formatStudyType(value: string): string | undefined {
		return this.STUDY_TYPE_MAPPINGS?.get(value) || undefined;
	}

	/**
	 * @description Standard formatter for processing boolean values
	 * @param value Boolean value as defined in the external system
	 * @return Formatted boolean if value is provided
	 */
	protected formatBoolean(value: string): boolean | undefined {
		return value?.length ? value === 'true' : undefined;
	}

	/**
	 * @description Standard formatter for processing string values
	 * @param value String value as defined in the external system
	 * @return Formatted string if value is provided
	 */
	protected formatString(value: string): string | undefined {
		return value?.length ? value.trim() : undefined;
	}

	/**
	 * @description Standard formatter for processing number values
	 * @param value Number value as defined in the external system
	 * @return Formatted number if value is provided
	 */
	protected formatNumber(value: string): number | undefined {
		return isNaN(+value) ? undefined : +value;
	}

	/**
	 * @description Standard formatter for processing date values
	 * @param value Date value as defined in the external system
	 * @return Parsed date value
	 */
	protected formatDate(value: string): Date | undefined {
		const dateString = this.formatString(value);

		return dateString ? DateTime.fromISO(value).toJSDate() : undefined;
	}

	/**
	 * @description Standard formatter for processing percentage values
	 * @param value Percentage string value as defined in the external system (assumes string in the form of: '50%')
	 * @return Parsed percentage as a number
	 */
	protected formatPercentage(value: string): number | undefined {
		const percentageString = this.formatString(value);
		const percentageValue = percentageString?.split('%')[0];

		if (percentageValue && !isNaN(+percentageValue)) {
			return +percentageValue;
		} else {
			return undefined;
		}
	}

	/**
	 * @description Calculates the difference between endTime and startTime in seconds
	 * @param startTime Start time of the study as defined in the external system
	 * @param endTime End time of the study as defined in the external system
	 * @return The duration of the study in seconds
	 */
	protected calculateDuration(startTime: string, endTime: string): number | undefined {
		const startTimeString = this.formatString(startTime);
		const endTimeString = this.formatString(endTime);

		if (startTimeString && endTimeString) {
			const formattedStartTime = DateTime.fromISO(startTime);
			const formattedEndTime = DateTime.fromISO(endTime);

			return formattedEndTime.diff(formattedStartTime, 'seconds').toObject().seconds;
		} else {
			return undefined;
		}
	}

	/**
	 * @description Returns a list of CSV headers that are required (varies per implementation)
	 * @return Array of CSV header labels
	 */
	public getRequiredFieldsArray(): string[] {
		return this.REQUIRED_FIELDS ? Object.values(this.REQUIRED_FIELDS) : [];
	}

	/**
	 * @description Formats and returns a WCD Study from the information captured in this.normalizedRowData
	 * @return WCD Study
	 */
	public getWcdStudy(): WcdStudy {
		return {
			id: ''
		};
	}

	/**
	 * @description Formats and returns a WCD Patient from the information captured in this.normalizedRowData
	 * @return WCD Patient
	 */
	public getWcdPatient(): WcdPatient {
		return {
			id: ''
		};
	}
}
