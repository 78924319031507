import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileService, ServicesModule } from '@irh/services';
import { UiModule } from '@irh/ui';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { StudyEffects } from './+state/study.effects';
import { StudyFacade } from './+state/study.facade';
import * as fromStudy from './+state/study.reducer';
import { UploadStudyCsvComponent } from './components/upload-study-csv/upload-study-csv.component';
import { StudyDashboardComponent } from './pages/study-dashboard/study-dashboard.component';
import { StudyService, UploadStudyCsvFacadeService } from './services';
import { WcdRoutingModule } from './wcd-routing.module';

@NgModule({
	declarations: [StudyDashboardComponent, UploadStudyCsvComponent],
	imports: [
		CommonModule,
		UiModule,
		WcdRoutingModule,
		ServicesModule,
		StoreModule.forFeature(fromStudy.STUDY_FEATURE_KEY, fromStudy.reducer),
		EffectsModule.forFeature([StudyEffects])
	],
	providers: [StudyFacade, StudyService, FileService, UploadStudyCsvFacadeService]
})
export class WcdModule {}
