import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ReportUploadOutput } from '../../interfaces';

@Component({
	selector: 'irh-report-upload-form',
	templateUrl: './report-upload-form.component.html',
	styleUrls: ['./report-upload-form.component.scss']
})
export class ReportUploadFormComponent {
	uploadFileForm: FormGroup;
	public readonly ERROR_MESSAGE = {
		alertIsRequired: 'Alert is required',
		fileIsRequired: 'File is required',
		deviceIsRequired: 'Device is required',
		serviceLineIsRequired: 'Service Line is required',
		typeIsRequired: 'Type is required',
		patientIdIsRequired: 'Patient Id is required'
	};
	constructor(private readonly fb: FormBuilder) {
		//ignoring because of https://github.com/typescript-eslint/typescript-eslint/issues/1929
		this.uploadFileForm = this.fb.group({
			// eslint-disable-next-line @typescript-eslint/unbound-method
			patientId: ['', [Validators.required]],
			// eslint-disable-next-line @typescript-eslint/unbound-method
			type: ['', [Validators.required]],
			// eslint-disable-next-line @typescript-eslint/unbound-method
			device: ['', [Validators.required]],
			// eslint-disable-next-line @typescript-eslint/unbound-method
			serviceLine: ['', [Validators.required]],
			// eslint-disable-next-line @typescript-eslint/unbound-method
			alert: ['', [Validators.required]],
			// eslint-disable-next-line @typescript-eslint/unbound-method
			file: [null, [Validators.required]]
		});
	}
	@Output() reportUploadForm: EventEmitter<ReportUploadOutput> =
		new EventEmitter<ReportUploadOutput>();
	public onFileChanged(event: Event) {
		const input = event.target as HTMLInputElement;
		this.uploadFileForm.patchValue({ file: input.files ? input.files[0] : null });
	}

	public uploadFile = () => {
		const outputValue: ReportUploadOutput = this.uploadFileForm
			.value as ReportUploadOutput;
		if (this.uploadFileForm.valid) {
			this.reportUploadForm.emit(outputValue);
		}
	};
}
