import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	ViewChild
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

import { ReportType } from '../../../../../interfaces/src/lib/report-types.interface';

@Component({
	selector: 'irh-patient-search',
	templateUrl: './patient-search.component.html',
	styleUrls: ['./patient-search.component.scss']
})
export class PatientSearchComponent implements AfterViewInit {
	@ViewChild('patientNameInput') patientNameInput!: ElementRef;
	@Input() patient = '';
	@Input() reportType: ReportType = 'rsw-we-eos-report';
	@Output() patientFieldChange: EventEmitter<string> = new EventEmitter<string>();
	@Output() reportTypeChange: EventEmitter<string> = new EventEmitter<string>();

	ngAfterViewInit() {
		fromEvent(this.patientNameInput.nativeElement as HTMLInputElement, 'keyup')
			.pipe(
				debounceTime(300),
				distinctUntilChanged(),
				tap(text => {
					this.patientFieldChange.emit(
						// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
						(text.target as HTMLInputElement).value
					);
				})
			)
			.subscribe();
	}

	patientFieldChanged($event: Event) {
		this.patientFieldChange.emit(($event.target as HTMLInputElement).value);
	}

	reportTypeChanged($event: Event) {
		this.reportTypeChange.emit(($event.target as HTMLInputElement).value);
	}
}
