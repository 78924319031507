<div class="app" fxLayout="column">
	<irh-menu-bar>
		<div fxLayoutAlign="space-between center" fxFlex="33">
			<img
				class="irh-logo"
				[routerLink]="['/']"
				src="../assets/IronRod-Health-Logo.webp"
				alt="Ironrod Health Logo"
			/>
		</div>
		<div fxLayoutAlign="space-between center" fxFlex="33">
			<nav mat-tab-nav-bar>
				<a
					mat-tab-link
					*ngFor="let link of routes"
					(click)="changeNavigation(link.path)"
					[active]="activeRoute === link.path"
				>
					{{ link.displayName }}
				</a>
			</nav>
		</div>

		<div fxLayoutAlign="end center" fxFlex="grow">
			<button
				mat-icon-button
				color="primary"
				[matMenuTriggerFor]="menu"
				aria-label="user profile"
			>
				<mat-icon>account_circle</mat-icon>
			</button>
		</div>

		<mat-menu #menu="matMenu">
			<ng-container *ngIf="authFacade.auth$ | async as authState">
				<button mat-menu-item *ngIf="authState.isLoggedIn" (click)="logout()">
					<mat-icon>exit_to_app</mat-icon>
					<span>Logout</span>
				</button>
			</ng-container>
		</mat-menu>
	</irh-menu-bar>

	<div fxFlex="grow">
		<router-outlet></router-outlet>
	</div>
</div>
