import { createAction, props } from '@ngrx/store';

enum AuthActionTypes {
	Login = '[Auth/API] Login',
	CheckLogin = '[Auth/API] Check Login',
	LoginComplete = '[Auth/API] Login Complete',
	LoginSuccess = '[Auth/API] Login Success',
	LoginFailure = '[Auth/API] Login Failure',
	Logout = '[Auth/API] Logout'
}

export const login = createAction(AuthActionTypes.Login);
export const checkLogin = createAction(AuthActionTypes.CheckLogin);
export const loginComplete = createAction(AuthActionTypes.LoginComplete);
export const loginSuccess = createAction(AuthActionTypes.LoginSuccess);
export const loginFailure = createAction(
	AuthActionTypes.LoginFailure,
	props<{ error: string }>()
);
export const logout = createAction(AuthActionTypes.Logout);
