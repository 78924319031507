<mat-card>
	<mat-card-header>
		<mat-card-title>Reset Password</mat-card-title>
		<mat-card-subtitle>Enter your email to confirm a password reset</mat-card-subtitle>
	</mat-card-header>
	<mat-card-content>
		<form [formGroup]="resetPasswordForm">
			<mat-form-field>
				<mat-label>Email</mat-label>
				<input data-cy="resetEmail" matInput type="email" formControlName="email" />
				<mat-error
					*ngIf="
						resetPasswordForm.controls.email.hasError('email') &&
						!resetPasswordForm.controls.email.hasError('required')
					"
				>
					Please enter a valid email address
				</mat-error>
			</mat-form-field>
		</form>
	</mat-card-content>
	<mat-card-actions>
		<button
			data-cy="confirmResetButton"
			mat-raised-button
			color="primary"
			(click)="resetPassword()"
		>
			Confirm Reset Password
		</button>
	</mat-card-actions>
</mat-card>
