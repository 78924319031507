import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as StudyActions from './study.actions';
import { StudyEntity } from './study.models';

export const STUDY_FEATURE_KEY = 'study';

export interface State extends EntityState<StudyEntity> {
	selectedId?: string | number; // which Study record has been selected
	loaded: boolean; // has the Study list been loaded
	error?: Error | null; // last known error (if any)
}

export interface StudyPartialState {
	readonly [STUDY_FEATURE_KEY]: State;
}

export const studyAdapter: EntityAdapter<StudyEntity> =
	createEntityAdapter<StudyEntity>();

export const initialState: State = studyAdapter.getInitialState({
	// set initial required properties
	loaded: false
});

const studyReducer = createReducer(
	initialState,
	on(StudyActions.loadStudiesSuccess, (state, { payload }) => {
		return studyAdapter.setAll(payload, { ...state, loaded: true });
	}),
	on(StudyActions.loadStudiesFailure, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
	return studyReducer(state, action);
}
