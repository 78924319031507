import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ReportFilterBy, ReportSortBy } from '@irh/interfaces';

@Component({
	selector: 'irh-report-sort-filter',
	templateUrl: './report-sort-filter.component.html',
	styleUrls: ['./report-sort-filter.component.scss']
})
export class ReportSortFilterComponent {
	@Input() sortBy: ReportSortBy = 'newest';
	@Input() filterBy: ReportFilterBy = 'todo';
	@Output() sortByChange: EventEmitter<string> = new EventEmitter<string>();
	@Output() filterByChange: EventEmitter<string> = new EventEmitter<string>();

	sortByChanged($event: MatButtonToggleChange) {
		this.sortByChange.emit($event.value as string);
	}

	filterByChanged($event: MatButtonToggleChange) {
		this.filterByChange.emit($event.value as string);
	}
}
