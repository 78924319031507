<h2>Upload Study CSV Component</h2>
<irh-upload-study-csv></irh-upload-study-csv>

<hr />

<h2>Study Dashboard Table</h2>
<irh-data-table
	[dataSource]="dataTableInput.dataSource"
	[columnsToDisplay]="dataTableInput.columnsToDisplay"
	[caption]="dataTableInput.caption"
></irh-data-table>
