import { MatTableDataSource } from '@angular/material/table';

/**
 * @description Valid input values for the Data Table component
 */
export interface DataTableComponentInput {
	dataSource: MatTableDataSource<DataTableRow>;
	columnsToDisplay: DataTableColumn[];
	caption: string;
}

/**
 * @description A mat-table row in the Data Table component
 */
export interface DataTableRow {
	[property: string]: string | number;
}

/**
 * @description A column that is used in the Data Table component
 */
export interface DataTableColumn {
	property: string; //API name used to retrieve a column's value from the DataTableRow (e.g. {name: 'Test'} would have a property of 'name')
	label: string; //column header used in the mat-table
}
