import { Component, Input } from '@angular/core';
@Component({
	selector: 'irh-print-download',
	templateUrl: './print-download.component.html',
	styleUrls: ['./print-download.component.scss']
})
export class PrintDownloadComponent {
	@Input() showDownload!: boolean;
	@Input() showPrint!: boolean;
	@Input() fileUrl!: string;

	download(): void {
		if (typeof this.fileUrl === 'string') {
			const downloadLink = this.createDownloadLink(this.fileUrl);
			this.mimicUserClick(downloadLink);
		} else {
			console.error('Cannot download as File is undefined', this.fileUrl);
		}
	}

	print(): void {
		if (typeof this.fileUrl === 'string') {
			window.open(this.fileUrl)?.print();
		} else {
			console.error('Cannot print as File is undefined', this.fileUrl);
		}
	}

	private createDownloadLink(url: string): HTMLAnchorElement {
		const element = document.createElement('a');
		element.setAttribute('target', '_blank');
		element.setAttribute('href', url);
		element.setAttribute('download', this.fileUrl);
		return element;
	}

	private mimicUserClick(element: HTMLAnchorElement): void {
		// make sure on injection it is invisible to the user
		element.style.visibility = 'hidden';
		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element);
	}
}
