import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Auth0Result } from 'auth0-js';
import { EMPTY, of } from 'rxjs';
import { catchError, exhaustMap, mergeMap, tap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import * as AuthActions from './auth.actions';

@Injectable()
export class AuthEffects {
	login$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(AuthActions.login),
				tap(() => {
					this.authService.login();
				})
			);
		},
		{ dispatch: false }
	);

	//called on app init
	checkLogin$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AuthActions.checkLogin),
			exhaustMap(() => {
				if (this.authService.isAuthenticated) {
					return this.authService.checkSession$({}).pipe(
						mergeMap(authResult => {
							return this.authService.validateAuthResult(authResult as Auth0Result);
						}),
						catchError((error: Error) => {
							this.authService.resetAuthFlag();
							return of(AuthActions.loginFailure({ error: error.message }));
						})
					);
				} else {
					return EMPTY;
				}
			})
		);
	});

	//is called when checkLogin / loginSuccess effect is successful
	loginSuccess$ = createEffect(
		() => {
			return this.actions$.pipe(ofType(AuthActions.loginSuccess));
		},
		{ dispatch: false }
	);

	//handle errors thrown during authentication process
	loginFailure$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(AuthActions.loginFailure),
				tap(() => {
					void this.router.navigate([this.authService.authFailureUrl]);
				})
			);
		},
		{ dispatch: false }
	);

	//called from callback component onInit
	loginComplete$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AuthActions.loginComplete),
			exhaustMap(() => {
				return this.authService.parseHash$().pipe(
					mergeMap(authResult => {
						return this.authService.validateAuthResult(authResult as Auth0Result);
					}),
					catchError((error: Error) => {
						return of(AuthActions.loginFailure({ error: error.message }));
					})
				);
			})
		);
	});

	logout$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(AuthActions.logout),
				tap(() => this.authService.logout())
			);
		},
		{ dispatch: false }
	);

	constructor(
		private readonly authService: AuthService,
		private readonly actions$: Actions,
		private readonly router: Router
	) { }
}
