import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import * as auth0 from 'auth0-js';
import { Auth0Result } from 'auth0-js';
import { bindNodeCallback, Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import * as AuthActions from '../+state/auth.actions';

/**
 * @description This service should only be used by the Auth Feature Store (NgRx)
 */
@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private readonly _Auth0 = new auth0.WebAuth({
		clientID: environment.auth.clientID,
		domain: environment.auth.domain,
		responseType: 'token',
		redirectUri: `${window.location.origin}/auth/callback`,
		audience: environment.auth.audience
	});

	private readonly _authFlag = 'isLoggedIn';
	get authFlag(): string {
		return this._authFlag;
	}

	private readonly _authSuccessUrl = '/home';
	get authSuccessUrl(): string {
		return this._authSuccessUrl;
	}

	private readonly _authFailureUrl = '/auth/login';
	get authFailureUrl(): string {
		return this._authFailureUrl;
	}

	private readonly _loginUrl = '/auth/login';
	get loginUrl(): string {
		return this._loginUrl;
	}

	get isAuthenticated(): boolean {
		return JSON.parse(localStorage.getItem(this._authFlag) ?? 'false') as boolean;
	}

	private _expiresAt!: number;

	public readonly ERROR_MESSAGES = {
		INVALID_AUTH_RESULT: 'Invalid auth result.'
	};

	// Create observable of Auth0 parseHash method to gather auth results
	parseHash$ = bindNodeCallback(this._Auth0.parseHash.bind(this._Auth0));

	// Create observable of Auth0 checkSession method to
	// verify authorization server session and renew tokens
	checkSession$ = bindNodeCallback(this._Auth0.checkSession.bind(this._Auth0));

	/**
	 * @description Resets authentication status in localStorage
	 * @return void
	 */
	resetAuthFlag() {
		localStorage.removeItem(this._authFlag);
	}

	/**
	 * @description Invokes Auth0 authorization flow
	 * @return void
	 */
	login() {
		this._Auth0.authorize();
	}

	/**
	 * @description Updates auth information in localStorage
	 * @param authResult Result from the Auth0 authorization flow
	 * @return void
	 */
	setAuth(authResult: Auth0Result) {
		this._expiresAt = (authResult.expiresIn ?? 0) * 1000 + Date.now();
		// Set flag in local storage stating this app is logged in
		localStorage.setItem(this._authFlag, JSON.stringify(true));
		if (authResult.accessToken) {
			localStorage.setItem('access_token', authResult.accessToken);
		}
	}

	/**
	 * @description Resets the auth status in localStorage and runs Auth0 logout flow
	 * @return void
	 */
	logout() {
		// Set authentication status flag in local storage to false
		this.resetAuthFlag();
		// This does a refresh and redirects back to homepage
		// Make sure you have the logout URL in your Auth0
		// Dashboard Application settings in Allowed Logout URLs
		this._Auth0.logout({
			clientID: environment.auth.clientID,
			returnTo: `${window.location.origin}`
		});
	}

	/**
	 * @description Validates authResult returned from parseHash$ or checkSession$
	 * @param authResult Output from parseHash$ or checkSession$
	 * @return LoginSuccess() action or throws an error for invalid auth result
	 */
	public validateAuthResult(authResult: Auth0Result): Observable<Action> {
		if (!authResult?.accessToken) {
			throw new Error(this.ERROR_MESSAGES.INVALID_AUTH_RESULT);
		} else {
			this.setAuth(authResult);
			window.location.hash = '';
			return of(AuthActions.loginSuccess());
		}
	}
}
