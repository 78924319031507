import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as StudyActions from './study.actions';
import * as StudySelectors from './study.selectors';

@Injectable()
export class StudyFacade {
	/**
	 * Combine pieces of state using createSelector,
	 * and expose them as observables through the facade.
	 */
	loaded$ = this.store.pipe(select(StudySelectors.getStudyLoaded));
	allStudies$ = this.store.pipe(select(StudySelectors.getAllStudy));
	selectedStudy$ = this.store.pipe(select(StudySelectors.getSelected));

	constructor(private readonly store: Store) {}

	/**
	 * @description Dispatches action to load studies
	 * @return void
	 */
	public loadAllStudies(): void {
		this.store.dispatch(StudyActions.loadStudies());
	}
}
