import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'irh-add-notes',
	templateUrl: './add-notes.component.html',
	styleUrls: ['./add-notes.component.scss']
})
export class AddNotesComponent {
	@Input() notes = '';
	@Output() submitNote: EventEmitter<string> = new EventEmitter<string>();

	submit() {
		this.submitNote.emit(this.notes);
		return this.notes;
	}

	notesChanged($event: Event) {
		this.notes = ($event.target as HTMLInputElement).value;
	}
}
