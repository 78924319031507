import { Injectable, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataTableColumn, DataTableComponentInput, DataTableRow } from '@irh/ui';
import { takeWhile } from 'rxjs/operators';

import { StudyFacade } from '../+state/study.facade';
import { StudyEntity } from '../+state/study.models';

//these table column property values must match the WCD Study / WCD Patient data model
//	key is the API name of the data row property; value is the label of the respective column
export const STUDY_TABLE_COLUMN_MAPPINGS: Map<string, string> = new Map<string, string>([
	/* ----- ID and Name are provided here just to display mock data -- can be removed if they should not be displayed in the future ----- */
	['id', 'ID'],
	['name', 'Name'],
	/* ----- study data that should be displayed on the table ----- */
	['patientName', 'Patient Name'],
	['dob', 'Date of Birth'],
	['providerName', 'Ordering Provider'],
	['clinic', 'Clinic'],
	['studyStatus', 'Study Status'],
	['studyType', 'Study Type'],
	['studyStart', 'Study Start'],
	['studyEnd', 'Study End'],
	['deviceSerialNumber', 'Device Serial Number'],
	['dayNum', 'Day #']
]);
//caption to display on UI that describes the table contents
export const DATA_TABLE_CAPTION = 'WCD Study Information';

@Injectable({
	providedIn: 'root'
})
export class StudyDashboardFacadeService implements OnDestroy {
	//flag that indicates whether the observables should unsubscribe or not
	private serviceIsActive = true;

	//dataSource that provides data for the StudyDashboard view
	private _dataSource!: MatTableDataSource<DataTableRow>;
	get dataSource() {
		return this._dataSource;
	}

	constructor(private readonly studyFacade: StudyFacade) {
		//init the datasource on construction
		this.initDataSource();

		//init subscriptions
		this.initSubscriptions();
	}

	ngOnDestroy(): void {
		//unsubscribe from observables
		this.serviceIsActive = false;
	}

	/**
	 * @description Initializes subscriptions that support this service
	 * @return void
	 */
	private initSubscriptions(): void {
		this.studyFacade.allStudies$
			.pipe(takeWhile(() => this.serviceIsActive))
			.subscribe((studies: StudyEntity[]) => {
				this._dataSource.data = studies as unknown as DataTableRow[];
			});
	}

	/**
	 * @description Initializes the data source for the Study Dashboard Table
	 * @return void
	 */
	private initDataSource(): void {
		this.loadStudies();
		this._dataSource = new MatTableDataSource<DataTableRow>([]);
	}

	/**
	 * @description Formats provided Map<string, string> into DataTableColumn[] for consumption by the DataTableComponent
	 * @param columnMappings Column information as a Map of objectProperty => columnLabel
	 * @return Formatted DataTableColumn[]
	 */
	public formatStudyTableColumnMappings(
		columnMappings: Map<string, string>
	): DataTableColumn[] {
		return Array.from(columnMappings.keys()).map(key => ({
			property: key,
			label: columnMappings.get(key) || ''
		}));
	}

	/**
	 * @description Initializes the data table configuration
	 * @return Configuration for the DataTableComponent
	 */
	public getDataTableInput(): DataTableComponentInput {
		return {
			dataSource: this.dataSource,
			columnsToDisplay: this.formatStudyTableColumnMappings(STUDY_TABLE_COLUMN_MAPPINGS),
			caption: DATA_TABLE_CAPTION
		};
	}

	/**
	 * @description Triggers action to load studies
	 * @return void
	 */
	public loadStudies(): void {
		this.studyFacade.loadAllStudies();
	}
}
