<form [formGroup]="uploadFileForm">
	<mat-card>
		<mat-card-header>
			<mat-card-title>Upload Report</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<mat-form-field>
				<input required formControlName="patientId" matInput placeholder="Patient ID" />
				<mat-error *ngIf="uploadFileForm.controls['patientId'].hasError('required')">
					{{ ERROR_MESSAGE.patientIdIsRequired }}
				</mat-error>
			</mat-form-field>
			<mat-form-field>
				<select matNativeControl formControlName="type" required placeholder="Type">
					<option value="rsw-we-eos-report">rsw-we-eos-report</option>
					<option value="rsw-mct-eos-report">rsw-mct-eos-report</option>
					<option value="cloudbeat-eos-report">cloudbeat-eos-report</option>
					<option value="implicity-90-day-remote-report">
						implicity-90-day-remote-report
					</option>
					<option value="FileMaker-30-day-ILR-Report">FileMaker-30-day-ILR-Report</option>
				</select>
				<mat-error *ngIf="uploadFileForm.controls['type'].hasError('required')">
					{{ ERROR_MESSAGE.typeIsRequired }}
				</mat-error>
			</mat-form-field>
			<mat-form-field>
				<input required formControlName="device" matInput placeholder="Device" />
				<mat-error *ngIf="uploadFileForm.controls['device'].hasError('required')">
					{{ ERROR_MESSAGE.deviceIsRequired }}
				</mat-error>
			</mat-form-field>
			<mat-form-field>
				<select
					matNativeControl
					formControlName="serviceLine"
					required
					placeholder="Service Line"
				>
					<option value="wcd">WCD</option>
					<option value="rpm">RPM</option>
					<option value="crm">CRM</option>
				</select>
				<mat-error *ngIf="uploadFileForm.controls['serviceLine'].hasError('required')">
					{{ ERROR_MESSAGE.serviceLineIsRequired }}
				</mat-error>
			</mat-form-field>
			<mat-form-field>
				<mat-label>Alert</mat-label>
				<select matNativeControl formControlName="alert" required placeholder="Alert">
					<option value="high">High</option>
					<option value="medium">Medium</option>
					<option value="low">Low</option>
				</select>
				<mat-error *ngIf="uploadFileForm.controls['alert'].hasError('required')">
					{{ ERROR_MESSAGE.alertIsRequired }}
				</mat-error>
			</mat-form-field>
			<input
				matinput
				(change)="onFileChanged($event)"
				accept="application/pdf"
				type="file"
			/>
			<mat-error *ngIf="uploadFileForm.controls['file'].hasError('required')">
				{{ ERROR_MESSAGE.fileIsRequired }}
			</mat-error>
		</mat-card-content>
		<mat-card-actions>
			<button (click)="uploadFile()" mat-raised-button color="primary">Upload</button>
		</mat-card-actions>
	</mat-card>
</form>
