import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HttpOptions } from '../interfaces';
import { ServicesModule } from '../services.module';

@Injectable({
	providedIn: ServicesModule
})
export class RestApiService {
	private readonly httpClient: HttpClient;
	constructor(client: HttpClient) {
		this.httpClient = client;
	}

	get(path: string, httpParams?: HttpParams, httpHeaders?: HttpHeaders) {
		const options = { params: httpParams, headers: httpHeaders } as HttpOptions;
		return this.httpClient.get(path, options);
	}

	put<T>(path: string, body: T, httpParams?: HttpParams, httpHeaders?: HttpHeaders) {
		const options = { params: httpParams, headers: httpHeaders } as HttpOptions;
		return this.httpClient.put(path, body, options);
	}

	post<T>(path: string, body: T, httpParams?: HttpParams, httpHeaders?: HttpHeaders) {
		const options = { params: httpParams, headers: httpHeaders } as HttpOptions;
		return this.httpClient.post(path, body, options);
	}

	patch<T>(path: string, body: T, httpParams?: HttpParams, httpHeaders?: HttpHeaders) {
		const options = { params: httpParams, headers: httpHeaders } as HttpOptions;
		return this.httpClient.patch(path, body, options);
	}

	delete<T>(path: string, body: T, httpParams?: HttpParams, httpHeaders?: HttpHeaders) {
		const options = {
			params: httpParams,
			headers: httpHeaders,
			body: body
		} as HttpOptions;
		return this.httpClient.delete(path, options);
	}
}
