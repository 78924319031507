<div layout="row" layout-align="center center">
	<form [formGroup]="uploadFileForm">
		<div>
			<mat-form-field
				data-cy="fileTypeSelect"
				appearance="outline"
				*ngIf="fileTypeOptions?.length"
			>
				<mat-label>{{ fileTypeLabel }}</mat-label>
				<mat-select formControlName="selectedFileType">
					<mat-option *ngFor="let fileType of fileTypeOptions" [value]="fileType.value">
						{{ fileType.label }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<div>
			<input
				data-cy="fileUploadInput"
				#fileUploadInput
				type="file"
				(change)="handleSelectFile($event)"
			/>
		</div>

		<div>
			<button
				data-cy="uploadFileButton"
				mat-button
				color="primary"
				(click)="uploadFile()"
				[disabled]="!uploadFileForm.valid"
			>
				{{ uploadFileButtonLabel }}
			</button>
		</div>

		<div data-cy="uiMessageContainer" class="body-1" *ngIf="uiMessage">
			{{ uiMessage }}
		</div>
	</form>
</div>
