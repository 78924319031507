import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';

import { AuthFacade } from '../+state/auth.facade';
import { AuthService } from '../services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(
		private readonly authService: AuthService,
		private readonly authFacade: AuthFacade,
		private readonly router: Router
	) {}

	canActivate(): Observable<boolean | UrlTree> {
		return this.authFacade.auth$.pipe(
			take(1),
			mergeMap(auth => {
				//TO DO TODO: add role-based authorization
				/* ----- check if there is previous authentication in auth store or local storage ----- */
				//check auth store for previous authentication
				if (auth.isLoggedIn) {
					return of(true);
				}
				//else check local storage for previous authentication
				else {
					return of(this.authService.isAuthenticated);
				}
			}),
			//redirect the user based on their current authentication status
			map((hasPreviousAuth: boolean) => {
				if (!hasPreviousAuth) {
					void this.router.navigate([this.authService.loginUrl]);
					return false;
				}
				return true;
			})
		);
	}
}
