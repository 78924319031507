import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { DataTableColumn, DataTableRow } from '../../interfaces';

@Component({
	selector: 'irh-data-table',
	templateUrl: './data-table.component.html',
	styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {
	//dataSource that feeds data into the mat-table
	@Input() dataSource!: MatTableDataSource<DataTableRow>;
	//list of table columns
	@Input() columnsToDisplay!: DataTableColumn[];
	//caption to display below data table
	@Input() caption!: string;

	//list of columnsToDisplay[*].label values to display in UI
	public displayedColumns!: string[];

	ngOnInit() {
		this.displayedColumns = this.columnsToDisplay.map(column => column.label);
	}
}
