import { ParsedCsvContent } from '@irh/services';
import { DateTime } from 'luxon';

import { WcdStudyTypes } from '../enums';
import { NormalizedStudyExportRowData } from '../interfaces';
import { StudyExportRowProcessor } from './StudyExportRowProcessor.class';

export class RhythmedixStudyExportRowProcessor extends StudyExportRowProcessor {
	//mapping of study types provided in Rhythmedix CSVs to WCD study types
	protected readonly STUDY_TYPE_MAPPINGS: Map<string, string> = new Map([
		['MCT', WcdStudyTypes.MCOT]
	]);

	//normalized data from the CSV row that is used to generate WCD studies, patients, etc.
	protected normalizedRowData!: NormalizedStudyExportRowData;

	//required fields in the form of { ALIAS_FOR_USE_IN_CODE: 'header value in CSV'}
	public readonly REQUIRED_FIELDS = {
		STUDY_ID: 'Study ID',
		PATIENT_ID: 'Patient ID',
		PATIENT_NAME: 'Patient Name',
		DATE_OF_BIRTH: 'Date Of Birth',
		PRIMARY_PHONE: 'Primary Phone',
		SECONDARY_PHONE: 'Secondary Phone',
		TIME_ZONE: 'Time zone',
		ADDRESS: 'Address',
		PRIMARY_INSURANCE_NAME: 'Primary Insurance Name',
		PRIMARY_INSURANCE_ID: 'Primary Insurance Id',
		SECONDARY_INSURANCE_NAME: 'Secondary Insurance Name',
		SECONDARY_INSURANCE_ID: 'Secondary Insurance Id',
		PATIENT_SEX: 'Patient Sex',
		PATIENT_AGE: 'Patient Age',
		DEVICE_SERIAL_NUMBER: 'Device Serial Number',
		DEVICE_ID: 'Device ID',
		DEVICE_MODEL: 'Device Model',
		DEVICE_MANUFACTURER: 'Device Manufacturer',
		DEVICE_IMEI: 'Device IMEI',
		MEDICAL_CENTER_NAME: 'Medical Center Name',
		PROVIDER_NAME: 'Provider Name',
		STUDY_TYPE: 'Study Type',
		START_TIME: 'Start Time',
		END_TIME: 'End Time',
		DURATION: 'Duration',
		CREATED_AT: 'Created At',
		ARCHIVED: 'Archived?',
		ARCHIVED_AT: 'Archived At',
		ALERTS: 'Alerts',
		TOTAL_NUMBER_EVENTS: 'Total Number of Events',
		PATIENT_TRIGGERED_EVENTS: 'Patient Triggered Events',
		AUTO_TRIGGERED_EVENTS: 'Auto-Triggered Events',
		STARTED: 'Started?',
		ICD_10: 'ICD-10',
		EMR_NUMBER: 'EMR #',
		DIAGNOSTICS_DURATION: 'Diagnostics Duration',
		DIAGNOSTICS_PERCENT: 'Diagnostics %'
	};

	constructor(rowData?: ParsedCsvContent) {
		super();

		//process the data and format for database
		if (rowData) {
			this.setNormalizedRowData(rowData);
		}
	}

	/**
	 * @description Processes the provided row data into normalized types to match WCD database schema
	 * @param rowData Data from the CSV row
	 * @return void
	 */
	protected setNormalizedRowData(rhythmedixRowData: ParsedCsvContent) {
		this.normalizedRowData = {
			studyId: this.formatString(rhythmedixRowData[this.REQUIRED_FIELDS.STUDY_ID]),
			patientId: this.formatString(rhythmedixRowData[this.REQUIRED_FIELDS.PATIENT_ID]),
			patientName: this.formatString(
				rhythmedixRowData[this.REQUIRED_FIELDS.PATIENT_NAME]
			),
			dob: this.formatBirthdate(rhythmedixRowData[this.REQUIRED_FIELDS.DATE_OF_BIRTH]), //format: 12/7/1900 12:00:00 AM
			phonePrimary: this.formatString(
				rhythmedixRowData[this.REQUIRED_FIELDS.PRIMARY_PHONE]
			),
			phoneSecondary: this.formatString(
				rhythmedixRowData[this.REQUIRED_FIELDS.SECONDARY_PHONE]
			),
			timeZone: this.formatString(rhythmedixRowData[this.REQUIRED_FIELDS.TIME_ZONE]),
			address: this.formatString(rhythmedixRowData[this.REQUIRED_FIELDS.ADDRESS]),
			insurancePrimaryName: this.formatString(
				rhythmedixRowData[this.REQUIRED_FIELDS.PRIMARY_INSURANCE_NAME]
			),
			insurancePrimaryId: this.formatString(
				rhythmedixRowData[this.REQUIRED_FIELDS.PRIMARY_INSURANCE_ID]
			),
			insuranceSecondaryName: this.formatString(
				rhythmedixRowData[this.REQUIRED_FIELDS.SECONDARY_INSURANCE_NAME]
			),
			insuranceSecondaryId: this.formatString(
				rhythmedixRowData[this.REQUIRED_FIELDS.SECONDARY_INSURANCE_ID]
			),
			patientSex: this.formatString(rhythmedixRowData[this.REQUIRED_FIELDS.PATIENT_SEX]),
			patientAge: this.formatNumber(rhythmedixRowData[this.REQUIRED_FIELDS.PATIENT_AGE]),
			deviceSerialNumber: this.formatString(
				rhythmedixRowData[this.REQUIRED_FIELDS.DEVICE_SERIAL_NUMBER]
			),
			deviceId: this.formatString(rhythmedixRowData[this.REQUIRED_FIELDS.DEVICE_ID]),
			deviceModel: this.formatString(
				rhythmedixRowData[this.REQUIRED_FIELDS.DEVICE_MODEL]
			),
			deviceManufacturer: this.formatString(
				rhythmedixRowData[this.REQUIRED_FIELDS.DEVICE_MANUFACTURER]
			),
			deviceImei: this.formatString(rhythmedixRowData[this.REQUIRED_FIELDS.DEVICE_IMEI]),
			medicalCenterName: this.formatString(
				rhythmedixRowData[this.REQUIRED_FIELDS.MEDICAL_CENTER_NAME]
			),
			providerName: this.formatString(
				rhythmedixRowData[this.REQUIRED_FIELDS.PROVIDER_NAME]
			),
			studyType: this.formatStudyType(rhythmedixRowData[this.REQUIRED_FIELDS.STUDY_TYPE]),
			startTime: this.formatDate(rhythmedixRowData[this.REQUIRED_FIELDS.START_TIME]), //format: 1900-01-01T17:21:33.0000000-04:00
			endTime: this.formatDate(rhythmedixRowData[this.REQUIRED_FIELDS.END_TIME]), //format: 1900-01-01T17:21:33.0000000-04:00
			durationInSeconds: this.calculateDuration(
				rhythmedixRowData[this.REQUIRED_FIELDS.START_TIME],
				rhythmedixRowData[this.REQUIRED_FIELDS.END_TIME]
			),
			createdAt: this.formatDate(rhythmedixRowData[this.REQUIRED_FIELDS.CREATED_AT]), //format: 1900-01-01T17:21:33.0000000-04:00
			isArchived: this.formatBoolean(rhythmedixRowData[this.REQUIRED_FIELDS.ARCHIVED]),
			archivedAt: this.formatDate(rhythmedixRowData[this.REQUIRED_FIELDS.ARCHIVED_AT]), //format: 1900-01-01T17:21:33.0000000-04:00
			alerts: this.formatString(rhythmedixRowData[this.REQUIRED_FIELDS.ALERTS]),
			numTotalEvents: this.formatNumber(
				rhythmedixRowData[this.REQUIRED_FIELDS.TOTAL_NUMBER_EVENTS]
			),
			numPatientEvents: this.formatNumber(
				rhythmedixRowData[this.REQUIRED_FIELDS.PATIENT_TRIGGERED_EVENTS]
			),
			numAutoTriggeredEvents: this.formatNumber(
				rhythmedixRowData[this.REQUIRED_FIELDS.AUTO_TRIGGERED_EVENTS]
			),
			isStarted: this.formatBoolean(rhythmedixRowData[this.REQUIRED_FIELDS.STARTED]),
			icd10: this.formatString(rhythmedixRowData[this.REQUIRED_FIELDS.ICD_10]),
			emrNum: this.formatString(rhythmedixRowData[this.REQUIRED_FIELDS.EMR_NUMBER]),
			diagnosticsDuration: undefined,
			diagnosticsPercentage: undefined
		};
	}

	/**
	 * @description Parses boolean values provided by Rhythmedix (valid values: 'NO', '0', 'YES', '1')
	 * @param value Value to format (Booleans are represented as either 'YES'/'NO' OR 1/0 in Rhythmedix CSVs)
	 * @return Value as boolean
	 */
	override formatBoolean(value: string): boolean | undefined {
		if (value?.length) {
			if (value === 'NO' || value === '0') {
				return false;
			} else if (value === 'YES' || value === '1') {
				return true;
			} else {
				return undefined;
			}
		} else {
			return undefined;
		}
	}

	/**
	 * @description Parses birthdate value provided by Rhythmedix (example value: 12/7/1900 12:00:00 AM)
	 * @param value Value to format
	 * @return Formatted birthdate in the format "MM/DD/YYYY"
	 */
	private formatBirthdate(value: string): string | undefined {
		const dobString = this.formatString(value);
		return dobString
			? DateTime.fromFormat(dobString, 'M/d/yyyy h:mm:ss a').toFormat('MM/dd/yyyy')
			: undefined;
	}
}
