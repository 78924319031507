import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { StudyDashboardComponent } from './pages/study-dashboard/study-dashboard.component';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'study-dashboard'
	},
	{
		path: 'study-dashboard',
		pathMatch: 'full',
		component: StudyDashboardComponent
	}
];

@NgModule({
	declarations: [],
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class WcdRoutingModule {}
