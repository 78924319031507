import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class ValidationService {
	public getPasswordValidators(): ((
		control: AbstractControl
	) => ValidationErrors | null)[] {
		return [
			// eslint-disable-next-line @typescript-eslint/unbound-method
			Validators.required,
			Validators.pattern(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
			)
		];
	}
}
