/* eslint-disable @typescript-eslint/no-unsafe-call */
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServicesModule } from '@irh/services';
import { MaterialModule, UiModule } from '@irh/ui';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { LoginComponent } from './auth/pages/login-page/login-page.component';
import { WcdModule } from './wcd/wcd.module';

@NgModule({
	declarations: [AppComponent, LoginComponent],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		WcdModule,
		AppRoutingModule,
		AuthModule,
		ServicesModule,
		FlexModule,
		UiModule,
		MaterialModule,
		StoreModule.forRoot(
			{},
			{
				metaReducers: [],
				runtimeChecks: {
					strictActionImmutability: true,
					strictStateImmutability: true
				}
			}
		),
		EffectsModule.forRoot([]),
		!environment.production ? StoreDevtoolsModule.instrument() : []
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
