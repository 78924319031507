import { Inject, Injectable } from '@angular/core';
import { FileService, ParsedCsvContent } from '@irh/services';
import { Observable } from 'rxjs';

import { StudyEntity } from '../+state/study.models';
import {
	CloudbeatStudyExportRowProcessor,
	RhythmedixStudyExportRowProcessor,
	StudyExportRowProcessor
} from '../classes';

//mock list of studies that will be returned until a supporting API is created
export const MOCK_STUDY_LIST = [
	{
		id: '1',
		name: 'Example Study 1'
	},
	{
		id: '2',
		name: 'Example Study 2'
	},
	{
		id: '3',
		name: 'Example Study 3'
	},
	{
		id: '4',
		name: 'Example Study 4'
	}
];

@Injectable({
	providedIn: 'root'
})
export class StudyService {
	//valid study source for WCD (source systems)
	public readonly STUDY_SOURCES = {
		RHYTHMEDIX: 'Rhythmedix',
		CLOUDBEAT: 'Cloudbeat'
	};

	//error messages to display on UI
	public readonly ERROR_MESSAGES = {
		UNSUPPORTED_STUDY_SOURCE:
			'Unsupported study source. The following study sources are currently supported: ' +
			Object.values(this.STUDY_SOURCES).join(', ')
	};

	constructor(@Inject(FileService) private readonly fileService: FileService) {}

	/**
	 * @description Parses data from the CSV into ParsedCsvContent[]
	 * @param file CSV file containing study information
	 * @return List of processed study data in the form of ParsedCsvContent[]
	 */
	public async parseStudyCsvData(file: File): Promise<ParsedCsvContent[]> {
		//parse the data from the file
		return this.fileService.parseFile(file);
	}

	/**
	 * @description Validates the provided Study CSV data
	 * @param studySource Source of file; a value from this.STUDY_SOURCES (e.g. Rhythmedix)
	 * @param data Row data from the CSV
	 * @return The parsed data from the Study CSV || error will be thrown from validation methods
	 */
	public validateStudyCsvData(
		studySource: string,
		data: ParsedCsvContent[]
	): ParsedCsvContent[] {
		/* ----- run validations on uploaded CSV file (and throws validation error if necessary) ----- */
		//validate there is >= 1 rows of data in the CSV
		this.fileService.validateCsvLength(data);
		//validate that ALL of the CSV headers exist in the CSV
		this.fileService.validateCsvHeaders(
			this.getStudyExportRequiredFields(studySource),
			data
		);

		return data;
	}

	/**
	 * @description Processes the provided data by constructing a StudyExportRowProcessor instance
	 * @param studySource Source of file; a value from this.STUDY_SOURCES (e.g. Rhythmedix)
	 * @param data Row data from the CSV
	 * @return Constructed processors used to normalize data from CSV
	 */
	public processStudyCsvData(
		studySource: string,
		data: ParsedCsvContent[]
	): StudyExportRowProcessor[] {
		//create WCD entities here (e.g. Study, Patient, etc.)
		return data.map((row: ParsedCsvContent) =>
			this.getStudyExportRowProcessor(studySource, row)
		);
	}

	/**
	 * @description Gets the required fields for the CSV data based on study source
	 * @param studySource Source of file; a value from this.STUDY_SOURCES (e.g. Rhythmedix)
	 * @param data Row data from the CSV
	 * @return List of required fields (column headers in CSV)
	 */
	private getStudyExportRequiredFields(studySource: string): string[] {
		//create an instance of the study export processor and return required fields
		return this.getStudyExportRowProcessor(studySource).getRequiredFieldsArray();
	}

	/**
	 * @description Gets a study export row processor based on the study source (used to validate headers in the provided exports)
	 * @param studySource Source of file; a value from this.STUDY_SOURCES (e.g. Rhythmedix)
	 * @param rowData Data from the CSV row (if not provided, a reference instance will be returned that contains the required fields
	 * 	that can be used to validate a CSV file)
	 * @return StudyExportRowProcessor instance based on the studySource and provided data
	 */
	private getStudyExportRowProcessor(
		studySource: string,
		rowData?: ParsedCsvContent
	): StudyExportRowProcessor {
		switch (studySource) {
			case this.STUDY_SOURCES.RHYTHMEDIX:
				return new RhythmedixStudyExportRowProcessor(rowData);
			case this.STUDY_SOURCES.CLOUDBEAT:
				return new CloudbeatStudyExportRowProcessor(rowData);
			default:
				throw new Error(this.ERROR_MESSAGES.UNSUPPORTED_STUDY_SOURCE);
		}
	}

	/**
	 * @description Gets list of studies
	 * @return List of studies
	 */
	public getStudies(): Observable<StudyEntity[]> {
		//mock response (will be updated when we connect to an API)
		return new Observable(subscriber => {
			subscriber.next(MOCK_STUDY_LIST);
			subscriber.complete();
		});
	}
}
