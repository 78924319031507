// eslint-disable-next-line simple-import-sort/imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
	DebounceTextareaComponent,
	AddNotesComponent,
	DataTableComponent,
	FileUploadComponent,
	FooterComponent,
	LoginComponent,
	MenuBarComponent,
	PrintDownloadComponent,
	ResetPasswordComponent,
	ReportSortFilterComponent,
	ReportUploadFormComponent,
	PatientSearchComponent
} from './components';
import { MaterialModule } from './material.module';

@NgModule({
	imports: [CommonModule, FormsModule, MaterialModule, ReactiveFormsModule, FlexModule],
	declarations: [
		FileUploadComponent,
		FooterComponent,
		DebounceTextareaComponent,
		LoginComponent,
		DataTableComponent,
		MenuBarComponent,
		ResetPasswordComponent,
		PrintDownloadComponent,
		ReportSortFilterComponent,
		ReportUploadFormComponent,
		AddNotesComponent,
		PatientSearchComponent
	],
	exports: [
		FileUploadComponent,
		FooterComponent,
		DebounceTextareaComponent,
		AddNotesComponent,
		DataTableComponent,
		MenuBarComponent,
		PrintDownloadComponent,
		ReportUploadFormComponent,
		PatientSearchComponent
	],
	providers: []
})
export class UiModule {}

export * from './interfaces';
