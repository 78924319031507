import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { StudyService } from '../services/study.service';
import * as StudyActions from './study.actions';
import { StudyEntity } from './study.models';

@Injectable()
export class StudyEffects {
	loadStudies$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(StudyActions.loadStudies),
			mergeMap(() => {
				return this.studyService.getStudies().pipe(
					map((studies: StudyEntity[]) =>
						StudyActions.loadStudiesSuccess({ payload: studies })
					),
					catchError((error: Error) => {
						return of(StudyActions.loadStudiesFailure({ error }));
					})
				);
			})
		);
	});

	constructor(
		private readonly actions$: Actions,
		private readonly studyService: StudyService
	) {}
}
