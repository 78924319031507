import { Component, OnInit } from '@angular/core';

import { AuthFacade } from '../../+state/auth.facade';

@Component({
	selector: 'irh-callback',
	templateUrl: './callback.component.html',
	styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {
	constructor(private readonly authFacade: AuthFacade) {}

	ngOnInit(): void {
		//dispatch login complete action
		this.authFacade.dispatchLoginComplete();
	}
}
