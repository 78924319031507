import { Component } from '@angular/core';

import { AuthFacade } from '../../+state/auth.facade';

@Component({
	selector: 'irh-login',
	templateUrl: './login-page.component.html',
	styleUrls: ['./login-page.component.scss']
})
export class LoginComponent {
	constructor(public readonly authFacade: AuthFacade) {}

	/**
	 * @description Initiate login process
	 * @return void
	 */
	public login() {
		this.authFacade.dispatchLogin();
	}
}
