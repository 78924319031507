<mat-card>
	<mat-card-header>
		<mat-card-title>Sign In</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<form [formGroup]="loginForm">
			<mat-form-field>
				<mat-label>Email</mat-label>
				<input data-cy="loginEmail" matInput type="email" formControlName="email" />
				<mat-error
					*ngIf="
						loginForm.get('email')?.hasError('email') &&
						!loginForm.get('email')?.hasError('required')
					"
				>
					{{ enterValidEmailMsg }}
				</mat-error>
				<mat-error *ngIf="loginForm.get('email')?.hasError('required')">
					{{ emailRequiredMsg }}
				</mat-error>
			</mat-form-field>
			<mat-form-field>
				<mat-label>Password</mat-label>
				<input
					data-cy="loginPassword"
					matInput
					type="password"
					formControlName="password"
				/>
				<mat-error *ngIf="loginForm.get('password')?.hasError('required')">
					{{ passwordRequiredMsg }}
				</mat-error>
				<mat-error *ngIf="loginForm.get('password')?.hasError('pattern')">
					{{ passwordInvalidMsg }}
				</mat-error>
			</mat-form-field>
		</form>
	</mat-card-content>
	<mat-card-actions>
		<button data-cy="loginButton" mat-raised-button color="primary" (click)="login()">
			Login
		</button>
	</mat-card-actions>
</mat-card>
