import { Component, OnInit } from '@angular/core';
import { DataTableComponentInput } from '@irh/ui';

import { StudyDashboardFacadeService } from '../../services';

@Component({
	selector: 'irh-study-dashboard',
	templateUrl: './study-dashboard.component.html',
	styleUrls: ['./study-dashboard.component.scss']
})
export class StudyDashboardComponent implements OnInit {
	//configuration for the data table component
	public dataTableInput!: DataTableComponentInput;

	constructor(private readonly studyDashboardFacade: StudyDashboardFacadeService) {}

	ngOnInit() {
		this.dataTableInput = this.studyDashboardFacade.getDataTableInput();
	}
}
