import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/guards';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
	},
	{
		path: 'home',
		loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'auth',
		loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
	},
	{
		path: 'wcd',
		loadChildren: () => import('./wcd/wcd.module').then(m => m.WcdModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'signature',
		loadChildren: () =>
			import('@irh/app-ironrod-health/signature/feature').then(
				m => m.AppIronrodHealthSignatureFeatureModule
			),
		canActivate: [AuthGuard]
	},
	{
		path: 'legal',
		loadChildren: () => import('./legal/legal.module').then(m => m.LegalModule)
	},
	{
		path: '**',
		redirectTo: '/not-found'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
